import { ICON_PROPS } from 'components/icons/constants';

const ChevronDown: React.FC<ICON_PROPS> = ({ height = 24, width = 24, color = '#181B28', viewBox = '0 0 24 24' }) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6 9L12 15L18 9' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default ChevronDown;
