import { ICON_PROPS } from 'components/icons/constants';

const Bank: React.FC<ICON_PROPS> = ({ height = 20, width = 21, color = '#2546F5' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M3.5 7.00002V15M8 7.00002V15M13 7.00002V15M17.5 7.00002V15M1.5 16.6L1.5 17.4C1.5 17.9601 1.5 18.2401 1.60899 18.454C1.70487 18.6422 1.85785 18.7952 2.04601 18.891C2.25992 19 2.53995 19 3.1 19H17.9C18.4601 19 18.7401 19 18.954 18.891C19.1422 18.7952 19.2951 18.6422 19.391 18.454C19.5 18.2401 19.5 17.9601 19.5 17.4V16.6C19.5 16.04 19.5 15.7599 19.391 15.546C19.2951 15.3579 19.1422 15.2049 18.954 15.109C18.7401 15 18.4601 15 17.9 15H3.1C2.53995 15 2.25992 15 2.04601 15.109C1.85785 15.2049 1.70487 15.3579 1.60899 15.546C1.5 15.7599 1.5 16.04 1.5 16.6ZM10.1529 1.07715L2.75291 2.7216C2.30585 2.82094 2.08232 2.87062 1.91546 2.99082C1.76829 3.09685 1.65273 3.24092 1.58115 3.40759C1.5 3.59654 1.5 3.82553 1.5 4.28349L1.5 5.40002C1.5 5.96007 1.5 6.2401 1.60899 6.45401C1.70487 6.64217 1.85785 6.79515 2.04601 6.89103C2.25992 7.00002 2.53995 7.00002 3.1 7.00002H17.9C18.4601 7.00002 18.7401 7.00002 18.954 6.89103C19.1422 6.79515 19.2951 6.64217 19.391 6.45401C19.5 6.2401 19.5 5.96007 19.5 5.40002V4.2835C19.5 3.82553 19.5 3.59655 19.4188 3.40759C19.3473 3.24092 19.2317 3.09685 19.0845 2.99082C18.9177 2.87062 18.6942 2.82094 18.2471 2.7216L10.8471 1.07715C10.7176 1.04837 10.6528 1.03398 10.5874 1.02824C10.5292 1.02314 10.4708 1.02314 10.4126 1.02824C10.3472 1.03398 10.2824 1.04837 10.1529 1.07715Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Bank;
