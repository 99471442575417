import { cloneElement, isValidElement } from 'react';
import { ICON_PROPS, ICONS, ICONS_MAP } from 'components/icons/constants';

interface IconWrapperProps extends ICON_PROPS {
  icon: ICONS;
  className?: string;
}

const IconWrapper: React.FC<IconWrapperProps> = ({
  icon,
  height,
  width,
  color,
  viewBox,
  className = '',
  orientation,
  strokeWidth,
}) => {
  const iconComponent = ICONS_MAP[icon as ICONS];

  const passProps: ICON_PROPS = { height, width, color, viewBox, orientation, strokeWidth };

  return (
    <div className={className}>{isValidElement(iconComponent) ? cloneElement(iconComponent, passProps) : null}</div>
  );
};

export default IconWrapper;
