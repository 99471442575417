import { ICON_PROPS } from 'components/icons/constants';

const ChevronRight: React.FC<ICON_PROPS> = ({ height = 24, width = 24, color = '#181B28', viewBox = '0 0 24 24' }) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M7.5 15L12.5 10L7.5 5' stroke={color} strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default ChevronRight;
