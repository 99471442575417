import { ICON_PROPS } from 'components/icons/constants';

const BankNote: React.FC<ICON_PROPS> = ({ height = 18, width = 23, color = '#2546F5' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 23 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.5 8V12M17.5 6V10M16.5 1C18.9487 1 20.2731 1.37476 20.9321 1.66544C21.0199 1.70415 21.0638 1.72351 21.1904 1.84437C21.2663 1.91682 21.4049 2.12939 21.4405 2.22809C21.5 2.39274 21.5 2.48274 21.5 2.66274V13.4111C21.5 14.3199 21.5 14.7743 21.3637 15.0079C21.2251 15.2454 21.0914 15.3559 20.8319 15.4472C20.5769 15.5369 20.062 15.438 19.0322 15.2401C18.3114 15.1017 17.4565 15 16.5 15C13.5 15 10.5 17 6.5 17C4.05129 17 2.72687 16.6252 2.06788 16.3346C1.98012 16.2958 1.93624 16.2765 1.8096 16.1556C1.73369 16.0832 1.59512 15.8706 1.55947 15.7719C1.5 15.6073 1.5 15.5173 1.5 15.3373L1.5 4.58885C1.5 3.68009 1.5 3.2257 1.63628 2.99214C1.7749 2.75456 1.90859 2.64412 2.16806 2.55281C2.42314 2.46305 2.93803 2.56198 3.96783 2.75985C4.68862 2.89834 5.54348 3 6.5 3C9.5 3 12.5 1 16.5 1ZM14 9C14 10.3807 12.8807 11.5 11.5 11.5C10.1193 11.5 9 10.3807 9 9C9 7.61929 10.1193 6.5 11.5 6.5C12.8807 6.5 14 7.61929 14 9Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BankNote;
