import { ICON_PROPS } from 'components/icons/constants';

const CoinsSwap01: React.FC<ICON_PROPS> = ({ height = 24, width = 24, color = '#181B28', viewBox = '0 0 24 24' }) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_5242_2643)'>
        <path
          d='M5.0013 4.99935L6.66797 3.33268M6.66797 3.33268L5.0013 1.66602M6.66797 3.33268H5.0013C3.16035 3.33268 1.66797 4.82507 1.66797 6.66602M15.0013 14.9993L13.3346 16.666M13.3346 16.666L15.0013 18.3327M13.3346 16.666H15.0013C16.8423 16.666 18.3346 15.1736 18.3346 13.3327M11.1823 11.1803C11.8342 11.4917 12.564 11.666 13.3346 11.666C16.0961 11.666 18.3346 9.42744 18.3346 6.66602C18.3346 3.90459 16.0961 1.66602 13.3346 1.66602C10.5732 1.66602 8.33464 3.90459 8.33464 6.66602C8.33464 7.43661 8.50896 8.16649 8.82033 8.81837M11.668 13.3327C11.668 16.0941 9.42939 18.3327 6.66797 18.3327C3.90655 18.3327 1.66797 16.0941 1.66797 13.3327C1.66797 10.5713 3.90655 8.33268 6.66797 8.33268C9.42939 8.33268 11.668 10.5713 11.668 13.3327Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_5242_2643'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CoinsSwap01;
