import { ICON_PROPS } from 'components/icons/constants';

const CurrencyEthereumCircle: React.FC<ICON_PROPS> = ({ height = 22, width = 22, color = '#2546F5' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.5 14.5L11.0002 17L16.5 14.5M21 11C21 16.5228 16.5228 21 11 21C5.47715 21 1 16.5228 1 11C1 5.47715 5.47715 1 11 1C16.5228 1 21 5.47715 21 11ZM5.5 10.5L11.0002 13L16.5 10.5L11.0002 4L5.5 10.5Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default CurrencyEthereumCircle;
