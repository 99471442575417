import { ORIENTATION_ENUMS } from 'constants/index';
import { ICON_PROPS } from 'components/icons/constants';

const DashedBorder: React.FC<ICON_PROPS> = ({
  height = 1,
  width = 1,
  color = '#E2E2E2',
  strokeDasharray = '3',
  strokeWidth = '3',
  orientation = ORIENTATION_ENUMS.HORIZONTAL,
}) => {
  const applicableWidth = orientation === ORIENTATION_ENUMS.VERTICAL ? width : '100%';
  const applicableHeight = orientation === ORIENTATION_ENUMS.VERTICAL ? '100%' : height;

  return (
    <svg width={applicableWidth} height={applicableHeight} fill='none' xmlns='http://www.w3.org/2000/svg'>
      <line
        x1={orientation === ORIENTATION_ENUMS.VERTICAL ? applicableWidth : 0}
        y1={orientation === ORIENTATION_ENUMS.VERTICAL ? 0 : applicableHeight}
        x2={applicableWidth}
        y2={applicableHeight}
        stroke={color}
        strokeDasharray={strokeDasharray}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default DashedBorder;
