import { ICON_PROPS } from 'components/icons/constants';

const Bank1: React.FC<ICON_PROPS> = ({ height = 32, width = 32, color = '#181B28' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width={width} height={height} fill={color} />
      <path
        d='M6.16767 12.875C6.40327 13.2831 6.83421 13.508 7.27418 13.5H24.7258C25.1658 13.508 25.5967 13.2831 25.8323 12.875C26.1775 12.2771 25.9727 11.5126 25.3748 11.1675C24.9762 10.9373 21.8513 9.21486 16 6C10.1487 9.21486 7.02376 10.9373 6.6252 11.1675C6.02734 11.5126 5.8225 12.2771 6.16767 12.875Z'
        fill='white'
      />
      <path
        d='M19.75 15.375V23.5H17.875V15.375H14.125V23.5H12.25V15.375H8.5V23.5H7.00021C6.44792 23.5 6.00021 23.9477 6.00021 24.5V26H25.9998V24.5C25.9998 23.9477 25.5521 23.5 24.9998 23.5H23.5V15.375H19.75Z'
        fill='white'
      />
    </svg>
  );
};

export default Bank1;
