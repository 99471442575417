import { ICON_PROPS } from 'components/icons/constants';

const Activity: React.FC<ICON_PROPS> = ({ height = 20, width = 23, color = '#2546F5' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 23 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.5 10H17.5L14.5 19L8.5 1L5.5 10H1.5'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Activity;
