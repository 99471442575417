import { ReactElement } from 'react';
import { ORIENTATION_ENUMS } from 'constants/index';
import Activity from 'components/icons/Activity';
import AlertTriangle from 'components/icons/AlertTriangle';
import ArrowNarrowRight from 'components/icons/ArrowNarrowRight';
import ArrowNarrowUp from 'components/icons/ArrowNarrowUp';
import ArrowUpRight from 'components/icons/ArrowUpRight';
import Bank from 'components/icons/Bank';
import Bank1 from 'components/icons/Bank1';
import BankNote from 'components/icons/BankNote';
import BulkArrow from 'components/icons/BulkArrow';
import Card from 'components/icons/Card';
import CheckVerified from 'components/icons/CheckVerified';
import ChevronDown from 'components/icons/ChevronDown';
import ChevronRight from 'components/icons/ChevronRight';
import Clock from 'components/icons/Clock';
import ClockCheck from 'components/icons/ClockCheck';
import ClockSnooze from 'components/icons/ClockSnooze';
import Coins03 from 'components/icons/Coins03';
import CoinsSwap01 from 'components/icons/CoinsSwap01';
import CurrencyEthereumCircle from 'components/icons/CurrencyEthereumCircle';
import DashedBorder from 'components/icons/DashedBorder';
import InfoCircle from 'components/icons/InfoCircle';
import Plus from 'components/icons/Plus';
import WaveBorder from 'components/icons/WaveBorder';

export enum ICONS {
  CHECK_VERIFIED = 'CHECK_VERIFIED',
  CLOCK = 'CLOCK',
  ALERT_TRIANGLE = 'ALERT_TRIANGLE',
  ACTIVITY = 'ACTIVITY',
  CARD = 'CARD',
  BANK_NOTE = 'BANK_NOTE',
  CURRENCY_ETHEREUM_CIRCLE = 'CURRENCY_ETHEREUM_CIRCLE',
  BANK = 'BANK',
  BULK_ARROW = 'BULK_ARROW',
  ARROW_NARROW_UP = 'ARROW_NARROW_UP',
  PLUS = 'PLUS',
  COINS_03 = 'COINS_03',
  INFO_CIRCLE = 'INFO_CIRCLE',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  COINS_SWAP_01 = 'COINS_SWAP_01',
  ARROW_UP_RIGHT = 'ARROW_UP_RIGHT',
  ARROW_NARROW_RIGHT = 'ARROW_NARROW_RIGHT',
  CLOCK_CHECK = 'CLOCK_CHECK',
  CLOCK_SNOOZE = 'CLOCK_SNOOZE',
  DASHED_BORDER = 'DASHED_BORDER',
  WAVE_BORDER = 'WAVE_BORDER',
  BANK_1 = 'BANK_1',
}

export const ICONS_MAP: Record<ICONS, ReactElement> = {
  [ICONS.CHECK_VERIFIED]: <CheckVerified />,
  [ICONS.CLOCK]: <Clock />,
  [ICONS.ALERT_TRIANGLE]: <AlertTriangle />,
  [ICONS.ACTIVITY]: <Activity />,
  [ICONS.CARD]: <Card />,
  [ICONS.BANK_NOTE]: <BankNote />,
  [ICONS.CURRENCY_ETHEREUM_CIRCLE]: <CurrencyEthereumCircle />,
  [ICONS.BANK]: <Bank />,
  [ICONS.BULK_ARROW]: <BulkArrow />,
  [ICONS.ARROW_NARROW_UP]: <ArrowNarrowUp />,
  [ICONS.PLUS]: <Plus />,
  [ICONS.COINS_03]: <Coins03 />,
  [ICONS.INFO_CIRCLE]: <InfoCircle />,
  [ICONS.CHEVRON_DOWN]: <ChevronDown />,
  [ICONS.CHEVRON_RIGHT]: <ChevronRight />,
  [ICONS.COINS_SWAP_01]: <CoinsSwap01 />,
  [ICONS.ARROW_UP_RIGHT]: <ArrowUpRight />,
  [ICONS.ARROW_NARROW_RIGHT]: <ArrowNarrowRight />,
  [ICONS.CLOCK_CHECK]: <ClockCheck />,
  [ICONS.CLOCK_SNOOZE]: <ClockSnooze />,
  [ICONS.DASHED_BORDER]: <DashedBorder />,
  [ICONS.WAVE_BORDER]: <WaveBorder />,
  [ICONS.BANK_1]: <Bank1 />,
};

export interface ICON_PROPS {
  width?: number;
  height?: number;
  color?: string;
  viewBox?: string;
  strokeDasharray?: string;
  strokeWidth?: string;
  orientation?: ORIENTATION_ENUMS;
}
