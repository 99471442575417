import { ICON_PROPS } from 'components/icons/constants';

const BulkArrow: React.FC<ICON_PROPS> = ({ height = 16, width = 16, color = '#2546F5' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1 15L15 1M15 1V6.16667M15 1H10.3333'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default BulkArrow;
