import { ICON_PROPS } from 'components/icons/constants';

const AlertTriangle: React.FC<ICON_PROPS> = ({ height = 21, width = 24, color = '#B12424' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M11.9978 7.99999V12M11.9978 16H12.0078M10.6131 2.89171L2.38823 17.0983C1.93203 17.8863 1.70393 18.2803 1.73764 18.6037C1.76705 18.8857 1.91482 19.142 2.14417 19.3088C2.40713 19.5 2.86239 19.5 3.77292 19.5H20.2227C21.1332 19.5 21.5885 19.5 21.8514 19.3088C22.0808 19.142 22.2286 18.8857 22.258 18.6037C22.2917 18.2803 22.0636 17.8863 21.6074 17.0983L13.3825 2.89171C12.9279 2.10654 12.7006 1.71396 12.4041 1.58211C12.1454 1.4671 11.8502 1.4671 11.5915 1.58211C11.295 1.71396 11.0677 2.10655 10.6131 2.89171Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default AlertTriangle;
