import { ICON_PROPS } from 'components/icons/constants';

const ArrowNarrowUp: React.FC<ICON_PROPS> = ({ height = 20, width = 23, color = '#181B28' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 23 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12 20V4M12 4L6 10M12 4L18 10'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowNarrowUp;
