import { ICON_PROPS } from 'components/icons/constants';

const ArrowUpRight: React.FC<ICON_PROPS> = ({ height = 21, width = 24, color = '#181B28' }) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 17L17 7M17 7H7M17 7V17'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ArrowUpRight;
